import React, {useState} from "react"
import { useSelector, useDispatch } from "react-redux"
import { useCookies } from 'react-cookie';
import SEO from "../components/seo/seo"
import Layout from "../components/layout/layout"
import AppBar from "../components/appBar/appBar"
import MainSection from "../components/mainSection/mainSection"
import SecondarySection from "../components/secondarySection/secondarySection"
import PageSection from "../components/pageSection/pageSection"
import AccordionGroup from "../components/accordionGroup/accordionGroup"
import Card from "../components/card/card"
import OptOut from "../components/optOut/optOut"
import IFrame from "../components/iFrame/iFrame"
import Modal from "../components/modal/modal"

import * as ACTIONS from "../state/actions"

const tcPdf = <IFrame file="../../TC-Plataforma.pdf" title="Terms of use"/>;
const pcPdf = <IFrame file="../../PC.pdf" title="Privacy policy"/>;
const footerDesc = "";
const contentMaxWidth = "780px";

const highYieldTab = <Card
    title="High Yield Digital accounts"
    description="<p>Whether you are an E-Commerce, Marketplace or Finance platform connect a high-yield business account into your business flow.</p>"
    descriptionColor="#363636"
    image="high-yield.png"
    imageWidth="250px"
    imageHeight="280px"
    marginTopContent="60px"
    //link="#"
    linkLabel="Learn more"
    horizontal
/>;

const b2bPayments = <Card
    title="B2B payments"
    description="<p>Ideal for ERP and Accounting Platforms, Pay and get paid for invoices quickly, simply, and without any of the usual costs.</p>"
    descriptionColor="#363636"
    image="b2b-payments.png"
    imageWidth="250px"
    imageHeight="280px"
    marginTopContent="60px"
    //link="#"
    linkLabel="Learn more"
    horizontal
/>;

const digitalAccountTab = <Card
    title="Digital account for landlords and tenants"
    description="<p>Are you a Property Mgmt Company? With our digital account for landlords and tenants to stay organized, save time and achieve passive income.</p>"
    descriptionColor="#363636"
    image="digital-account.png"
    imageWidth="250px"
    imageHeight="240px"
    marginTopContent="30px"
    //link="#"
    linkLabel="Learn more"
    horizontal
/>;

const financialWellnessTab = <Card
    title="Financial wellness for companies"
    description="<p>Are you a Payroll company Insurance  or HR company? With our high yield savings account say good bye to the financial stress of your collabroators.</p>"
    descriptionColor="#363636"
    image="financial-wellness.png"
    imageWidth="250px"
    imageHeight="280px"
    marginTopContent="60px"
    //link="#"
    linkLabel="Learn more"
    horizontal
/>;

const Platform = () => {
    const appState = useSelector(state => state.appReducer);
    const dispatch = useDispatch();

    const [cookies, setCookie] = useCookies(['consent']);

    const [itemSelected, setItemSelected] = useState(0);
    const items = [
        { id: 0, title: "E-commerce", content: highYieldTab },
        { id: 1, title: "Accounting", content: b2bPayments },
        { id: 2, title: "Proptech", content: digitalAccountTab },
        { id: 3, title: "Human Resources", content: financialWellnessTab }
    ];

    const launchFormHandler = () => {
        window.open("https://evvafinanzas.typeform.com/to/VFsgS0", "blank");
    };

    const termsUseHandler = () => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
        dispatch(ACTIONS.showModal({display: true, content: tcPdf }))
    };

    const privacyPolicyHandler = () => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
        dispatch(ACTIONS.showModal({display: true, content: pcPdf }))
    };

    const cookieHandler = () => {
        setCookie("consent", "dismiss", { path: '/' });
    };

    const closeModal = () => dispatch(ACTIONS.hideModal());

    return (
        <Layout lang={appState.lang} footerDesc={footerDesc} privacyPolicyAction={privacyPolicyHandler} termsUseHandlerAction={termsUseHandler}>
            <SEO lang={appState.lang} title="EVVA, the financial engine behind your Company." description="Startup, SME, eCommerce, Marketplace, develop and launch digital financial experiences that create value for your company and your customers."/>
            {appState.modal.display && <Modal content={appState.modal.content} closeModal={closeModal}/> }
            {!cookies.consent &&
            <OptOut text="This websites uses cookies to ensure that you get the best experience on our website." privacyPolicyAction={privacyPolicyHandler} privacyPolicyLabel="See Privacy Policy" cookieAction={cookieHandler}/>
            }
            <MainSection 
                title="The financial engine for you Business"
                description="<h3>Embed digital financial experiences that create value for you company and your customers.</h3>"
                textColor="primary"
                position="center"
                image="hero-platform.png"
                actionLabel="Connect now"
                action={launchFormHandler} 
                amplitude={appState.amplitude}
                amplitudeClickEvent="ClickPreSignUp"
            >
                <AppBar pathname={appState.path}/>
            </MainSection>
            <PageSection 
                title="Build better products"
                description="Wow your customers and grow your bottom line"
                maxColumns="2"
                maxWidth={contentMaxWidth}
            >
                <Card 
                    subtitle="Enrich your core business"
                    image="core-business.png"
                    imageWidth="378px"
                    imageHeight="274px"
                    description="<p>Drive customer lifetime value and higher revenues</p>"
                    imageLeft
                />
                <Card 
                    subtitle="Delight your customer"
                    image="testimonial.png"
                    imageWidth="378px"
                    imageHeight="274px"
                    description="<p>Increase engagement, retention, loyalty & customer satisfaction</p>"
                    imageLeft
                />
                <Card 
                    subtitle="Launch new digital experiences"
                    image="digital-experience.png"
                    imageWidth="378px"
                    imageHeight="274px"
                    description="<p>Lanch new products in days, not months</p>"
                    imageLeft
                />
                <Card 
                    subtitle="Your business, your brand"
                    image="business-brand.png"
                    imageWidth="378px"
                    imageHeight="274px"
                    description="<p>Customise financial services to fit your brand and your customers</p>"
                    imageLeft
                />
            </PageSection>
            <PageSection title="Beautiful financial products for your customers">
                <AccordionGroup items={items} itemSelected={itemSelected} setItemSelected={setItemSelected}/>
            </PageSection>
            <PageSection
                title="How do we do it"
                description="EVVA provides modular and ready made financial components, adding value to your customers and collaborators. We partner with the best financial partners in the market to make this happen."
                >
                    <div style={{textAlign: "center", margin: "0 auto"}}>
                        <img src="../../build-components.png" alt="See the impact you made" width="100%"/>
                    </div>
            </PageSection>
            <PageSection 
                maxColumns="4"
                title="Meet the plug & play platform"
                description="EVVA is built in partnership with a network of leading financial partners, making it easier to connect financial experiences in your platform. We have taken care of the upfront bank negotiations, compliances processes and regulatory requirements. It is frictionless to get started and scale."
            >
                <Card 
                    subtitle="Digital Account"
                    description="<p>Allow your customers & businesses to open a digital account at your fingerprints.</p>"
                    image="digital-account-icon.svg"
                    imageHeight="100px"
                    imageWidth="100px"
                    imageLeft
                />
                <Card 
                    subtitle="KYC Identification"
                    description="<p>Verify the identity of induviduals & companies. </p>"
                    image="kyc-icon.svg"
                    imageHeight="100px"
                    imageWidth="100px"
                    imageLeft
                />
                <Card 
                    subtitle="Escrow"
                    description="<p>Avoid fraud and secure your money with our digital escrow solution.</p>"
                    image="escrow-icon.svg"
                    imageHeight="100px"
                    imageWidth="100px"
                    imageLeft
                />
                <Card 
                    subtitle="Payments"
                    description="<p>No more late payments. Pay providers, bills right on time.</p>"
                    image="card-icon.svg"
                    imageHeight="100px"
                    imageWidth="100px"
                    imageLeft
                />
                <Card 
                    subtitle="Tax data"
                    description="<p>Connect your SAT account and get access to all your tax information.</p>"
                    image="tax-data-icon.svg"
                    imageHeight="100px"
                    imageWidth="100px"
                    imageLeft
                />
                <Card 
                    subtitle="Digital signature"
                    description="<p>Close your agreements remotely using your digital signature eFirma.</p>"
                    image="signature-icon-v2.svg"
                    imageHeight="100px"
                    imageWidth="100px"
                    imageLeft
                />
                <Card 
                    subtitle="International trading"
                    description="<p>Pay international providers with the best exchange rate.</p>"
                    image="international-trading-icon.svg"
                    imageHeight="100px"
                    imageWidth="100px"
                    imageLeft
                />
                <Card 
                    subtitle="Accounts overview"
                    description="<p>Connect all your bank accounts to get a 360 of your finances.</p>"
                    image="accounts-overview-icon.svg"
                    imageHeight="100px"
                    imageWidth="100px"
                    imageLeft
                />
            </PageSection>
            <SecondarySection
                title="Have something on your mind?"
                shortDescription="Get in touch and we'll help you explore how you can build a custom financial product that's right for your customers"
                position="center"
                image="evva-lego-bricks.png"
                actionLabel="Get in touch"
                action={launchFormHandler}
                amplitude={appState.amplitude}
                amplitudeClickEvent="ClickPreSignUp"
            />
        </Layout>
    )
};

export default Platform
