import React from "react";
import Icon from "../icon/icon"
import styles from "./accordionGroup.module.scss";

const AccordionGroup = ({ itemSelected, items, setItemSelected }) => {
    const setContent = content => ({ __html: content });

    return (
        <div className={styles["accordion-group"]}>
            <ul className={styles["accordion-group_list"]}>
                {items.map(item =>
                    <li
                        role="presentation"
                        key={item.id}
                        className={[
                            styles["accordion-group_list_item"],
                            itemSelected > item.id && item.id !== items.length -1 && item.id !== 0 ? styles["accordion-group_list_borderLeft"] : "",
                            itemSelected < item.id && item.id !== items.length -1 && item.id !== 0 ? styles["accordion-group_list_borderRight"] : "",
                            itemSelected === item.id ? styles["accordion-group_list_item_selected"] : ""
                        ].join(" ")}
                        onClick={() => setItemSelected(item.id)}
                        onKeyPress={() => setItemSelected(item.id)}
                    >
                        <div className={styles["accordion-group_list_item_title"]} >
                        {item.title}
                        <div className={styles["accordion-group_list_item_icon"]} >
                            <Icon icon={ itemSelected === item.id ? "minus-icon.png" : "add-icon.png"} size="20px"/>
                        </div>
                        </div>
                        {itemSelected === item.id &&
                        <div className={styles["accordion-group_content_mobile"]}>
                            {typeof item.content === "string" ? (
                                <div dangerouslySetInnerHTML={setContent(item.content)}/>
                            ):(
                                item.content
                            )}
                        </div>
                        }
                    </li>
                )}
            </ul>
            <div className={styles["accordion-group_content_desk"]}>
                {typeof items[itemSelected].content === "string" ? (
                <div dangerouslySetInnerHTML={setContent(items[itemSelected].content)}/>
                ):(
                    items[itemSelected].content
                )}
            </div>
        </div>
    );
};

export default AccordionGroup;